import React, {useCallback, useMemo} from 'react';
import MaterialTable, {Column} from '@material-table/core';

import {Tenant} from '../generated';
import {grey} from '@mui/material/colors';
import {Button, Paper, Tooltip} from '@mui/material';
import {Edit} from '@mui/icons-material';
import {MaterialTableIcons} from './MaterialTableIcons';
import {
	formatDate,
	isAuthorizedForTenantAdminRole,
	isAuthorizedForUserMgmtRole,
	viewportHeight,
} from './format';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import GroupsIcon from '@mui/icons-material/Groups';
import {caseInsensitiveSort} from "../utils/sortFunction";

interface TenantsTableViewProps {
	tenantsList: Tenant[];
	handleClickOpenTenantForm: any;
	handleClickUsers: any;
	handleClickResidences: any;
	userRoleList: string[];
}

export default function TenantsTableView({
	tenantsList,
	handleClickOpenTenantForm,
	handleClickUsers,
	handleClickResidences,
	userRoleList,
}: TenantsTableViewProps) {
	const tenantIdentifier = tenantsList[0]?.tenantIdentifier;

	const isAuthorizedTenantAdmin: boolean = useMemo(() => {
		return isAuthorizedForTenantAdminRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);

	const isAuthorizedUserMgmt: boolean = useMemo(() => {
		return isAuthorizedForUserMgmtRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);

	const columns: Column<Tenant>[] = [
		{
			title: 'Name',
			field: 'tenantIdentifier',
			customSort: (a: any, b: any) => caseInsensitiveSort(a, b, 'tenantIdentifier')
		},
		{
			title: 'Beschreibung',
			field: 'description', width: '40%',
			customSort: (a: any, b: any) => caseInsensitiveSort(a, b, 'description')
		},
		{
			title: 'letzte Änderung durch',
			field: 'userLastChange',
			customSort: (a: any, b: any) => caseInsensitiveSort(a, b, 'userLastChange')
		},
		{
			title: 'letzte Änderung am',
			field: 'lastChanged',
			defaultSort: 'asc',
			sorting: true,
			render: useCallback(
					(tenant: Tenant) => formatDate(tenant.lastChanged),
					[]
			),
		},
		{
			sorting: false,
			render: useCallback(
				(tenant: Tenant) => {
					return (
						<React.Fragment>
							<Tooltip
									title={isAuthorizedTenantAdmin ? 'Bearbeite Betreiber' : ''}>
								<span>
									<Button
											disabled={!isAuthorizedTenantAdmin}
											onClick={() => handleClickOpenTenantForm(false, tenant)}>
										<Edit
												color={isAuthorizedTenantAdmin ? 'primary' : 'secondary'}
										/>
									</Button>
								</span>
							</Tooltip>
							<Tooltip
								title={
									isAuthorizedTenantAdmin ? 'Wohnheime anzeigen/hinzufügen' : ''
								}>
								<span>
									<Button
										disabled={!isAuthorizedTenantAdmin}
										onClick={() => handleClickResidences(tenant)}>
										<OtherHousesIcon
											color={isAuthorizedTenantAdmin ? 'primary' : 'secondary'}
										/>
									</Button>
								</span>
							</Tooltip>
							<Tooltip
								title={
									isAuthorizedUserMgmt ? 'Nutzer anzeigen/hinzufügen' : ''
								}>
								<span>
									<Button
										disabled={!isAuthorizedUserMgmt}
										onClick={() => handleClickUsers(tenant)}>
										<GroupsIcon
											color={isAuthorizedUserMgmt ? 'primary' : 'secondary'}
										/>
									</Button>
								</span>
							</Tooltip>
						</React.Fragment>
					);
				},
				[isAuthorizedTenantAdmin]
			),
		},
	];

	return (
		<MaterialTable
			localization={{
				body: {
					emptyDataSourceMessage: 'Keine Betreiber hinterlegt.',
				},
			}}
			style={{
				borderRadius: '30px',
				fontSize: '12',
				color: grey[500],
			}}
			icons={MaterialTableIcons()}
			columns={columns}
			data={tenantsList}
			components={{
				Container: (props) => <Paper {...props} elevation={3} />,
			}}
			options={{
				paging: false,
				showTitle: false,
				sorting: true,
				filtering: true,
				search: false,
				rowStyle: {
					fontSize: 'subtitle2',
					overflowWrap: 'break-word',
				},
				headerStyle: {
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
				},
				maxBodyHeight: viewportHeight(80),
			}}
		/>
	);
}
