import { IconButton, IconButtonProps, styled, TextField } from '@mui/material';
import type { KeycloakTokenParsed } from 'keycloak-js';
import { BaseRole } from './Roles';

export function isLandscape(): boolean {
	return window.matchMedia('(orientation: landscape)').matches;
}

export function isAuthorizedForRole(
	userRoleList: string[],
	baseRole: string,
	tenantIdentifier: string | undefined
): boolean {
	if (tenantIdentifier == null) {
		return userRoleList.includes('R-' + baseRole);
	} else {
		return userRoleList.includes('R-' + baseRole + '-' + tenantIdentifier);
	}
}

export function isAuthorizedForGlobalAdminRole(
	userRoleList: string[]
): boolean {
	return (
		isAuthorizedForRole(userRoleList, BaseRole.ADMINISTRATION, undefined)
	);
}

export function isAuthorizedForTenantAdminRole(
	userRoleList: string[],
	tenantIdentifier: string | undefined
): boolean {
	return (
		isAuthorizedForRole(userRoleList, BaseRole.ADMINISTRATION, undefined) ||
		isAuthorizedForRole(userRoleList, BaseRole.ADMINISTRATION, tenantIdentifier)
	);
}

export function isAuthorizedForUserMgmtRole(
	userRoleList: string[],
	tenantIdentifier: string | undefined
): boolean {
	return (
		isAuthorizedForRole(userRoleList, BaseRole.ADMINISTRATION, undefined) ||
		isAuthorizedForRole(
			userRoleList,
			BaseRole.ADMINISTRATION,
			tenantIdentifier
		) ||
		isAuthorizedForRole(userRoleList, BaseRole.USERMANAGEMENT, tenantIdentifier)
	);
}

export function viewportHeight(v: number): number {
	var h = Math.max(
		document.documentElement.clientHeight,
		window.innerHeight || 0
	);
	return (v * h) / 100;
}

export function abbreviateString(text: string, length: number): string {
	return text?.length > length ? text.substring(0, length) + '...' : text;
}

export function isEmptyField(field: any): boolean {
	return (
		field === '' || field === null || field === undefined || field === void 0
	);
}

export function isUndefined(variable: any): boolean {
	return (
		typeof variable === 'undefined'
	);
}
export interface TenantData {
	tenantIdentifier: string;
	residenceIdentifier: string;
}

export function isInputKeycloakConform(str: string | undefined): boolean {
	const specialChars = /[`!@#$%^&*=\[\]{};':"\\|,<>?~ßäüöẞÄÜÖ ]/;
	return specialChars.test(str!);
}

/**
 * Function converts into date with format DD.MM.YYYY
 * @function
 * @param date
 */
export const formatDate = function (date?: Date): string {
	if (!date) return '';

	const options: Intl.DateTimeFormatOptions = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	};
	return new Intl.DateTimeFormat('de-DE', options).format(date);
};

export function MakeDisabledTextField(
	label: string,
	name: string,
	value: string
) {
	return (
		<TextField
			label={label}
			name={name}
			id='outlined-size-normal'
			value={value}
			disabled
		/>
	);
}

export const getAdminGroupName = function (tenantIdentifier: string): string {
	return 'G-TCC-Administration-' + tenantIdentifier;
};

export const getUserNameWithTenantSuffix = function (
	userName: string,
	tenantIdentifier: string
): string {
	return userName + '@' + tenantIdentifier.toLowerCase();
};

export type ParsedToken = KeycloakTokenParsed & {
	email?: string;
	preferred_username?: string;
	given_name?: string;
	family_name?: string;
};

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));