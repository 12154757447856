import React, {useState} from 'react';
import {Close, Save} from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {getNetworkApi} from './keycloak';

import {Tenant, TenantInsertParameter, TenantUpdateParameter,} from '../generated';
import {formatDate, isEmptyField, isInputKeycloakConform} from './format';

interface TenantFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isNewTenant: boolean;
	displayTenant: boolean;
	selectedTenant: Tenant;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	tenantList: Tenant[];
}

export default function TenantForm(prop: TenantFormProp) {
	const {
		open,
		setOpen,
		isNewTenant,
		displayTenant,
		selectedTenant,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
		tenantList,
	} = prop;

	const [tenant, setTenant] = useState(selectedTenant);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setTenant({
			...tenant,
			[name]: value,
		});
	};

	const saveTenant = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let tenantInsertParameter: TenantInsertParameter = {
			description: tenant.description,
		};

		try {
			await client.postTenant(
					tenant.tenantIdentifier as string,
					tenantInsertParameter
			);
			setAlertText('Betreiber wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Betreiber wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateTenant = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let tenantUpdateParameter: TenantUpdateParameter = {
			description: tenant.description,
		};
		try {
			await client.putTenant(
					tenant.tenantIdentifier as string,
					tenantUpdateParameter
			);
			setAlertText('Betreiber wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Betreiber wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const isSaveButtonEnabled = (tenant: Tenant) => {
		return isNewTenant
			? !isEmptyField(tenant?.tenantIdentifier) &&
					!isEmptyField(tenant?.description) &&
					!isTenantIdAlreadyAssigned(tenant) &&
					!isInputKeycloakConform(tenant.tenantIdentifier)
			: !isEmptyField(tenant?.tenantIdentifier) &&
					!isEmptyField(tenant?.description);
	};

	const isTenantIdAlreadyAssigned = (tenant: Tenant) => {
		if (tenant?.tenantIdentifier !== undefined) {
			return (
				tenantList.findIndex(
					(el) => el.tenantIdentifier === tenant.tenantIdentifier
				) !== -1
			);
		} else {
			return false;
		}
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth={'md'}>
				<DialogTitle>
					{displayTenant
						? 'Betreiber anzeigen'
						: [isNewTenant ? 'Betreiber anlegen' : 'Betreiber bearbeiten']}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Betreiber'
								name='tenantIdentifier'
								id='outlined-size-normal'
								value={tenant.tenantIdentifier}
								onChange={handleInputChange}
								inputProps={{ maxLength: 50 }}
								required
								disabled={displayTenant || !isNewTenant}
								error={
									isTenantIdAlreadyAssigned(tenant) &&
									isNewTenant &&
									isInputKeycloakConform(tenant.tenantIdentifier)
								}
								helperText={
									isInputKeycloakConform(tenant.tenantIdentifier)
										? 'Keine Sonderzeichen und Umlaute erlaubt!'
										: isTenantIdAlreadyAssigned(tenant) && isNewTenant
										? 'Betreiber bereits vorhanden!'
										: ''
								}
							/>
						</Grid>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Beschreibung'
								name='description'
								id='outlined-size-normal'
								value={tenant?.description}
								onChange={handleInputChange}
								inputProps={{ maxLength: 100 }}
								required
								disabled={displayTenant}
							/>
						</Grid>
						{displayTenant && (
							<Grid item xs={6}>
								<TextField
									fullWidth
									multiline
									rows={2}
									label='letzte Änderung durch'
									name='userLastChange'
									id='outlined-size-normal'
									value={tenant?.userLastChange}
									disabled
								/>
							</Grid>
						)}
						{displayTenant && (
							<Grid item xs={6}>
								<TextField
									fullWidth
									multiline
									rows={2}
									label='letzte Änderung am'
									name='lastChanged'
									id='outlined-size-normal'
									value={formatDate(tenant?.lastChanged)}
									disabled
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
								spacing={2}>
								<Grid item>
									<Button variant='contained' onClick={handleClose}>
										{<Close />} {displayTenant ? 'Schließen' : 'Abbrechen'}
									</Button>
								</Grid>
								{!displayTenant && (
									<Grid item>
										<Button
											variant='contained'
											disabled={!isSaveButtonEnabled(tenant)}
											onClick={isNewTenant ? saveTenant : updateTenant}>
											{<Save />} Speichern
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
