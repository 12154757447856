import {Add, Delete, Edit} from '@mui/icons-material';
import {Button, Grid, Paper, Tooltip} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ErrorAlert, SuccessAlert} from '../components/Alerts';
import keycloak, {getNetworkApi} from '../components/keycloak';
import {User} from '../generated';
import {trackPromise} from 'react-promise-tracker';
import MaterialTable, {Column} from '@material-table/core';
import {
	formatDate,
	isAuthorizedForTenantAdminRole,
	isAuthorizedForUserMgmtRole,
	ParsedToken,
	viewportHeight,
} from '../components/format';
import TabPanel from '../components/TabPanel';
import {MaterialTableIcons} from '../MaterialTableIcons';
import {grey} from '@mui/material/colors';
import PersonIcon from '@mui/icons-material/Person';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UserForm from '../components/UserForm';
import Confirm from '../components/Confirm';
import {useLocation} from 'react-router-dom';
import UploadUserFileForm from '../components/UploadUserFileForm';
import UsersFromFileForm from '../components/UsersFromFileForm';
import {caseInsensitiveSort} from "../utils/sortFunction";

export default function Users() {
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [userList, setUserList] = useState([] as User[]);
	const [materialTableLocalization, setMaterialTableLocalization] = useState({
		body: {
			emptyDataSourceMessage: 'Keine Nutzerinnen und Nutzer vorhanden.',
		},
		pagination: {
			labelDisplayedRows: '{from}-{to} von {count}',
			labelRowsSelect: 'Zeilen',
			labelRowsPerPage: 'Zeilen pro Seite:',
		},
	});
	const [showUserForm, setShowUserForm] = useState(false);
	const [showConfirmUserDeletion, setShowConfirmUserDeletion] = useState(false);
	const [userIdForDeletion, setUserIdForDeletion] = useState(
		undefined as unknown as string
	);
	const [deleteText, setDeleteText] = useState('');
	const [showUploadUserFileForm, setShowUploadUserFileForm] = useState(false);
	const [usersFromFile, setUsersFromFile] = useState([] as User[]);
	const [showUsersFromFileForm, setShowUsersFromFileForm] = useState(false);
	const [userRoleList, setUserRoleList] = useState([] as string[]);

	const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
	const isOwnUser = (user: User) => {
		return parsedToken?.preferred_username === user.username;
	};

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const location = useLocation();
	const tenantIdentifier = location.pathname.substring(
		location.pathname.indexOf('tenantId=') + 9,
		location.pathname.indexOf('/users')
	);

	const isAuthorizedTenantAdmin: boolean = useMemo(() => {
		return isAuthorizedForTenantAdminRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);

	const isAuthorizedUserMgmt: boolean = useMemo(() => {
		return isAuthorizedForUserMgmtRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);

	useEffect(() => {
		const loadUserList = async () => {
			const api = getNetworkApi();

			try {
				const result = await api.getUsers(tenantIdentifier);
				setUserList(result);
			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the user data!');
						setShowErrorAlert(true);
						setAlertText(
							'Nutzerinnen und Nutzer konnten nicht abgerufen werden'
						);
					}
				} else {
					console.log('There was an error fetching the email data!');
					setShowErrorAlert(true);
					setAlertText('Nutzerinnen und Nutzer konnten nicht abgerufen werden');
				}
			}
		};
		trackPromise(loadUserList());
	}, [showSuccessAlert]);

	const deleteUser = async () => {
		const client = getNetworkApi();
		try {
			await client.deleteUser(userIdForDeletion);
			setShowSuccessAlert(true);
			setShowConfirmUserDeletion(false);
			setAlertText('Nutzer:in wurde erfolgreich gelöscht');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Nutzer:in konnte nicht gelöscht werden');
			console.log('There was an error while deleting: ' + userIdForDeletion);
		}
	};

	const columns: Column<any>[] = [
		{
			title: 'Nutzername',
			field: 'username',
			sorting: true,
			customSort: (a: any, b: any) => caseInsensitiveSort(a, b, 'username'),
			filtering: true,
			render: useCallback((user: User) => user.username, []),
		},
		{
			title: 'Vorname',
			field: 'firstName',
			sorting: true,
			customSort: (a: any, b: any) => caseInsensitiveSort(a, b, 'firstName'),
			filtering: true,
			render: useCallback((user: User) => user.firstName, []),
		},
		{
			title: 'Nachname',
			field: 'lastName',
			sorting: true,
			customSort: (a: any, b: any) => caseInsensitiveSort(a, b, 'lastName'),
			filtering: true,
			render: useCallback((user: User) => user.lastName, []),
		},
		{
			title: 'Nutzer angelegt am',
			field: 'inputJson',
			sorting: false,
			filtering: false,
			render: useCallback((user: User) => formatDate(user.created), []),
		},
		{
			sorting: false,
			render: useCallback(
				(user: User) => {
					return (
						<React.Fragment>
							<Tooltip title={'Bearbeite Nutzer'}>
								<span>
									<Button
										disabled={!isAuthorizedUserMgmt || isOwnUser(user)}
										onClick={() => handleClickOpenUserForm(false, user)}>
										<Edit />
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={'Lösche Nutzer'}>
								<span>
									<Button
										disabled={!isAuthorizedUserMgmt || isOwnUser(user)}
										onClick={() => handleDeleteUser(user.userId)}>
										<Delete />
									</Button>
								</span>
							</Tooltip>
						</React.Fragment>
					);
				},
				[isAuthorizedUserMgmt]
			),
		},
	];

	const [selectedUser, setSelectedUser] = useState(
		undefined as unknown as User
	);
	const [isNewUser, setIsNewUser] = useState(false);

	const handleClickOpenUserForm = (isNewUser: boolean, user: User) => {
		setSelectedUser(user);
		setIsNewUser(isNewUser);
		setShowUserForm(true);
	};

	const handleDeleteUser = (userId: string) => {
		setDeleteText('Wollen Sie die Nutzer/den Nutzer wirklich löschen?');
		setUserIdForDeletion(userId);
		setShowConfirmUserDeletion(true);
	};

	const handleClickUploadUsersFile = () => {
		setShowUploadUserFileForm(true);
	};

	return (
		<React.Fragment>
			<TabPanel index={0} value={0}>
				<Grid container direction='row' spacing={2} sx={{ padding: 5 }}>
					<Grid item xs={12}>
						<Button
							variant='contained'
							sx={{ float: 'right' }}
							disabled={!isAuthorizedUserMgmt}
							onClick={() => handleClickOpenUserForm(true, undefined!)}>
							{<Add />} {<PersonIcon />}
						</Button>
						<Tooltip
							title={
								isAuthorizedUserMgmt
									? 'Mitarbeiter:innen aus Datei importieren'
									: ''
							}>
							<Button
								variant='contained'
								sx={{ float: 'right', mr: 5 }}
								disabled={!isAuthorizedUserMgmt}
								onClick={() => handleClickUploadUsersFile()}>
								{<FileUploadIcon />}
								{<PersonIcon />}
							</Button>
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ mx: 5 }}>
					<MaterialTable
						localization={materialTableLocalization}
						style={{ borderRadius: '30px', color: grey[500] }}
						icons={MaterialTableIcons()}
						columns={columns}
						data={userList}
						components={{
							Container: (props) => <Paper {...props} elevation={3} />,
						}}
						options={{
							paging: true,
							showTitle: false,
							sorting: true,
							filtering: true,
							search: false,
							rowStyle: { fontSize: 16 },
							headerStyle: {
								position: 'sticky',
								top: 0,
								backgroundColor: 'white',
							},
							maxBodyHeight: viewportHeight(80),
						}}
					/>
				</Grid>
			</TabPanel>
			{showUserForm && (
				<UserForm
					open={showUserForm}
					setOpen={setShowUserForm}
					isNewUser={isNewUser}
					selectedUser={selectedUser}
					currentUsers={userList}
					isAuthorizedAdmin={isAuthorizedTenantAdmin}
					isAuthorizedUserMgmt={isAuthorizedUserMgmt}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showConfirmUserDeletion && (
				<Confirm
					open={showConfirmUserDeletion}
					setOpen={setShowConfirmUserDeletion}
					text={deleteText}
					executefct={deleteUser}
				/>
			)}
			{showUploadUserFileForm && (
				<UploadUserFileForm
					open={showUploadUserFileForm}
					setOpen={setShowUploadUserFileForm}
					tenantIdentifier={tenantIdentifier}
					setUsersFromFile={setUsersFromFile}
					setShowUsersFromFileForm={setShowUsersFromFileForm}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showUsersFromFileForm && (
				<UsersFromFileForm
					open={showUsersFromFileForm}
					setOpen={setShowUsersFromFileForm}
					usersFromFile={usersFromFile}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
