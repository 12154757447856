import React, {useCallback} from 'react';
import MaterialTable, {Column} from '@material-table/core';

import {Unit} from '../generated';
import {grey} from '@mui/material/colors';
import {Button, Paper, Tooltip} from '@mui/material';
import {Edit} from '@mui/icons-material';
import {MaterialTableIcons} from './MaterialTableIcons';
import {formatDate, viewportHeight} from './format';
import {caseInsensitiveSort} from "../utils/sortFunction";

interface UnitTableViewProps {
	unitList: Unit[];
	handleClickOpenUnitForm: any;
	isAuthorized: boolean;
}

export default function UnitTableView({
	unitList,
	handleClickOpenUnitForm,
	isAuthorized,
}: UnitTableViewProps) {
	const columns: Column<Unit>[] = [
		{
			title: 'Name',
			field: 'unitIdentifier',
			customSort: (a: any, b: any) => caseInsensitiveSort(a, b, 'unitIdentifier')
		},
		{
			title: 'Beschreibung',
			field: 'description', width: '40%',
			customSort: (a: any, b: any) => caseInsensitiveSort(a, b, 'description')
		},
		{
			title: 'letzte Änderung durch',
			field: 'userLastChange',
			customSort: (a: any, b: any) => caseInsensitiveSort(a, b, 'userLastChange')
		},
		{
			title: 'letzte Änderung am',
			field: 'lastChanged',
			defaultSort: 'asc',
			sorting: true,
			render: useCallback(
					(unit: Unit) => formatDate(unit.lastChanged),
					[]
			),
		},
		{
			sorting: false,
			render: useCallback(
				(unit: Unit) => {
					return (
						<React.Fragment>
							<Tooltip title={isAuthorized ? 'Bearbeite Wohnbereich' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() =>
											handleClickOpenUnitForm(false, unit)
										}>
										<Edit color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
						</React.Fragment>
					);
				},
				[isAuthorized]
			),
		},
	];

	return (
		<MaterialTable
			localization={{
				body: {
					emptyDataSourceMessage: 'Kein Wohnbereich hinterlegt.',
				},
			}}
			style={{
				borderRadius: '30px',
				fontSize: '12',
				color: grey[500],
			}}
			icons={MaterialTableIcons()}
			columns={columns}
			data={unitList}
			components={{
				Container: (props) => <Paper {...props} elevation={3} />,
			}}
			options={{
				paging: false,
				showTitle: false,
				sorting: true,
				filtering: true,
				search: false,
				rowStyle: {
					fontSize: 'subtitle2',
					overflowWrap: 'break-word',
				},
				headerStyle: {
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
				},
				maxBodyHeight: viewportHeight(80),
			}}
		/>
	);
}
